import { WidgetLayout } from '../widgets/useWidgetLayout';
import { WidgetType } from '../widgets/widgetEvents';
import { defaultCustomerWidgetLayouts } from './defaultCustomerWidgetLayouts';

export const defaultCustomerWidgetsLayout: WidgetLayout = {
  layout: {
    lg: [
      {
        ...defaultCustomerWidgetLayouts.articles.lg,
        i: '0',
      },
    ],
  },
  widgets: [
    {
      type: WidgetType.Articles,
      id: '0',
      layoutIndex: '0',
      name: WidgetType.Articles,
      categoryColor: null,
    },
  ],
  currentLayout: [
    {
      ...defaultCustomerWidgetLayouts.articles.lg,
      i: '0',
    },
  ],
};
