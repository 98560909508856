import {
  CardBody,
  Flex,
  IconButton,
  Tooltip,
  Text,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import {
  Composition,
  UpdateCompositionRequest,
} from '@texas/api/endpoints/compositionApi';
import { useContext, useEffect, useState } from 'react';
import { CompositionView } from './composition/CompositionView';
import { CompositionOverview } from './composition/CompositionOverview';
import { useTranslation } from 'react-i18next';
import { useFormSubmitStore } from './hooks/useFormSubmit';
import { ViewState } from './types';
import { TemplateCard } from './composition/TemplateCard';
import { UpdateCompositionFormSubmitProvider } from './updateCompositionFormSubmitContext';
import { Icons } from '@texas/components/shared/Icons';
import { GroupContext } from './group/CompositionGroupView';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { reactEvents } from '@bridge/reactEvents';
import { compositionGroupEvents } from './events';

export function TemplateView({
  composition,
  imageIdentifier,
  productGroupId,
  onLoaded,
}: {
  composition: Composition;
  imageIdentifier: string;
  productGroupId: number | null;
  onLoaded?: (template: Composition | null) => void;
}) {
  const { updateCompositionRequest, refetchGroup } = useContext(GroupContext)!;

  const [state, setState] = useState<ViewState>('view');

  useEffect(() => {
    return reactEvents.productApprovalsCreated.sub(() => setState('view'));
  }, []);

  useEffect(() => {
    if (!onLoaded) return;
    onLoaded(composition);
  }, [composition, onLoaded]);

  const formState = useFormSubmitStore({
    defaultValues: {
      weight: composition.weight,
      dimensions: composition.dimension?.dimensions.map((x) => ({
        id: x.id,
        value: x.value,
      })),
    },
    submit: (data: UpdateCompositionRequest) =>
      updateCompositionRequest(composition.id, data),
  });

  return (
    <UpdateCompositionFormSubmitProvider value={formState}>
      <TemplateCard exists={!composition.archived}>
        <CardBody
          p={0}
          as={Flex}
          overflowX="hidden"
          flexDir="column"
          border="1px solid"
          bg="texas.bg.dark"
          borderColor="texas.bg.700"
          _light={{ borderColor: 'gray.200', bg: 'white' }}
        >
          <>
            {state === 'view' && (
              <CompositionView
                imageIdentifier={imageIdentifier}
                composition={composition}
                archiveDisabled={true}
              />
            )}
            {state === 'edit' && (
              <Box p={2}>
                <CompositionOverview
                  onMaterialChanged={() => {
                    refetchGroup();
                    compositionGroupEvents.compositionMaterialUpdated.dispatch();
                  }}
                  composition={composition}
                  productGroupId={productGroupId}
                  onClose={() => setState('view')}
                />
              </Box>
            )}
          </>
          <Footer
            composition={composition}
            state={state}
            stateChanged={(state) => setState(state)}
          />
        </CardBody>
      </TemplateCard>
    </UpdateCompositionFormSubmitProvider>
  );
}

function Footer({
  state,
  composition,
  stateChanged,
}: {
  state: ViewState;
  composition: Composition;
  stateChanged: (string: ViewState) => void;
}) {
  const { t } = useTranslation();
  const {
    archiveCompositionRequest,
    restoreCompositionRequest,
    componentSettings,
    anyProductApproval,
  } = useContext(GroupContext)!;
  const { isOpen, onClose, onOpen } = useDisclosure();

  if (
    anyProductApproval ||
    (componentSettings?.compositionEdit === 'hidden' &&
      componentSettings.compositionArchiveRestore === 'hidden')
  ) {
    return null;
  }

  return (
    <Flex
      pos="sticky"
      bottom={0}
      mt="auto"
      gap={2}
      alignItems="center"
      p={2}
      borderRadius="md"
      bg="texas.bg.800"
      _light={{ bg: 'gray.50' }}
    >
      {!composition.archived ? (
        <>
          {componentSettings?.compositionEdit !== 'hidden' && (
            <Tooltip
              label={
                state === 'view' ? t('composition.edit') : t('general.back')
              }
            >
              <IconButton
                aria-label="Edit"
                icon={state === 'edit' ? <Icons.ArrowLeft /> : <Icons.Pencil />}
                onClick={() => {
                  if (state === 'view') {
                    stateChanged('edit');
                    return;
                  }
                  stateChanged('view');
                }}
              />
            </Tooltip>
          )}

          {componentSettings?.compositionArchiveRestore !== 'hidden' && (
            <Tooltip label={t('composition.archiveComposition')}>
              <IconButton
                onClick={onOpen}
                variant="ghost"
                icon={<Icons.Archive />}
                aria-label="archive composition"
              />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <Text>
            {t('general.archivedDate', {
              date: formatDate(Locale.En, composition.archived),
            })}
          </Text>
          {componentSettings?.compositionArchiveRestore !== 'hidden' && (
            <Tooltip label={t('general.restore')}>
              <IconButton
                onClick={() => restoreCompositionRequest(composition.id)}
                variant="ghost"
                icon={<Icons.ArchiveRemove />}
                aria-label="restore composition"
              />
            </Tooltip>
          )}
        </>
      )}
      <VerifyDialogWithFunction
        headerTitle={t('composition.archiveComposition')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={() => archiveCompositionRequest(composition.id)}
        isOpen={isOpen}
        onClose={onClose}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
    </Flex>
  );
}
