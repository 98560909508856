import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CompositionGroupOverview } from '@texas/api/endpoints/compositionGroupApi';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { useTranslation } from 'react-i18next';
import { UpdateGroupModal } from '../shared/group/UpdateGroupModal';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { OptionsContext } from './OptionsOverview';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { ConfirmCopy } from '../shared/group/ConfirmCopy';
import { useDraggable } from '@dnd-kit/core';

export function OptionItem({
  group,
  onClick,
}: {
  group: CompositionGroupOverview;
  onClick: () => void;
}) {
  const { t } = useTranslation();
  const {
    isOpen: isEditGroupOpen,
    onClose: onEditGroupClose,
    onOpen: onEditGroupOpen,
    value: editGroupValue,
  } = useValueDisclosure<{ id: number; note: string | null }>();

  const { isOpen, onClose, onOpen, value } = useValueDisclosure<{
    version: number;
    id: number;
    title: string;
  }>();

  const { refetchVersions, copyCompositionGroupRequest } =
    useActiveContext(OptionsContext);

  return (
    <>
      <Button
        as={Flex}
        variant="comp-version"
        onClick={onClick}
        alignItems="center"
        role="group"
        gap={2}
      >
        <Flex flexDir="column">
          <Text>
            {group.cellX
              ? t('composition.cellOptionNr', { nr: group.version })
              : t('composition.optionNr', { nr: group.version })}
          </Text>
          <Text variant="sub" fontSize="sm">
            {group.note}
          </Text>
        </Flex>
        <Flex
          ml="auto"
          opacity={0}
          _groupHover={{ opacity: 1 }}
          gap={2}
          alignItems="center"
        >
          <Tooltip label={t('composition.clickToEdit')}>
            <Icons.Pencil />
          </Tooltip>
          <Tooltip label={t('dragAndDrop.dragToCopy')}>
            <Box>
              <Draggable id={group.id} />
            </Box>
          </Tooltip>
        </Flex>
        <Menu placement="top">
          <MenuButton
            as={IconButton}
            size="sm"
            variant="ghost"
            onClick={(e) => e.stopPropagation()}
            icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
          />
          <MenuList color="white">
            <MenuItem
              icon={<Icons.NoteEdit boxSize={defaultIconSize} />}
              onClick={(e) => {
                e.stopPropagation();
                onEditGroupOpen({ id: group.id, note: group.note });
              }}
            >
              {t('composition.editNote')}
            </MenuItem>
            <MenuItem
              icon={<Icons.ContentCopy boxSize={defaultIconSize} />}
              onClick={(e) => {
                e.stopPropagation();
                onOpen({
                  version: group.version,
                  id: group.id,
                  title: t('composition.copyIntoNewOption'),
                });
              }}
            >
              {t('composition.copyIntoNewOption')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Button>
      {value && (
        <ConfirmCopy
          title={value.title}
          isOpen={isOpen}
          onClose={onClose}
          compositionGroupId={value.id}
          onConfirm={(id) => {
            onClose();
            copyCompositionGroupRequest(id, value.id, undefined);
          }}
        />
      )}
      <UpdateGroupModal
        onUpdated={refetchVersions}
        group={editGroupValue}
        onClose={onEditGroupClose}
        isOpen={isEditGroupOpen}
      />
    </>
  );
}

function Draggable({ id }: { id: number }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id,
    data: {
      optionId: id,
    },
  });
  return (
    <IconButton
      icon={<Icons.Drag boxSize={defaultIconSize} />}
      aria-label="drag"
      size="sm"
      variant="ghost"
      cursor="grab"
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    />
  );
}
