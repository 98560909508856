export const defaultWidgetColor = 'texas.bg.800';
export const defaultWidgetLightColor = 'gray.100';

type State = 'hidden';

export interface WidgetComponentSettings {
  settingDots?: State;
}

export interface BaseWidgetOptions {
  componentSettings?: WidgetComponentSettings;
}
