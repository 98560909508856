import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { Icons } from '../shared/Icons';
import { TexasHeader } from '../shared/TexasHeader';
import { VerifyDialogWithFunction } from '../shared/dialog/VerifyDialogWithFunction';
import { EditWidget } from './EditWidget/EditWidget';
import { WidgetType } from './widgetEvents';
import { useTranslation } from 'react-i18next';
import { WidgetComponentSettings } from './types';

export type WidgetStyle = 'default' | 'borderless';

interface WidgetWrapperProps {
  children: React.ReactNode;
  toolbox?: React.ReactNode;
  style: WidgetStyle;
  header: string;
  customColor: string | null;
  onRemove: () => void;
  onUpdate: (name: string, color: string) => void;
  type: WidgetType;
  settings?: WidgetComponentSettings;
}

export function WidgetWrapper({
  children,
  style = 'default',
  toolbox,
  header,
  customColor,
  onRemove,
  onUpdate,
  type,
  settings,
}: WidgetWrapperProps) {
  const widgetStyle = GetWidgetStyle(style);
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    onOpen: onEditOpen,
  } = useDisclosure();
  return (
    <>
      <Flex height="100%" flexDirection="column">
        <TexasHeader text={header} />
        <Flex
          borderRadius={8}
          {...widgetStyle.widget}
          height="100%"
          minHeight={0}
        >
          <Flex
            className="header"
            {...widgetStyle.header}
            justifyContent="start"
            flexDir="column"
            p={2}
            gap={2}
            cursor="grab"
            {...(customColor ? { bg: `${customColor} !important` } : null)}
            _active={{ cursor: 'grabbing' }}
          >
            {!settings?.settingDots && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  size="xs"
                  variant="ghost"
                  aria-label="Widget settings"
                  icon={<Icons.DotsHorizontal boxSize="6" />}
                />
                <MenuList>
                  <MenuItem
                    onClick={onEditOpen}
                    icon={<Icons.Pencil boxSize={4} />}
                  >
                    {t('widgets.edit')}
                  </MenuItem>
                  <MenuItem onClick={onOpen} icon={<Icons.Trash boxSize={4} />}>
                    {t('widgets.remove')}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}

            {toolbox}
          </Flex>
          <Box width="100%" minH={0} padding={2} minW={0}>
            {children}
          </Box>
        </Flex>
      </Flex>
      <EditWidget
        name={header}
        type={type}
        color={customColor}
        onUpdate={onUpdate}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
      <VerifyDialogWithFunction
        headerTitle={t('general.remove')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={onRemove}
        isOpen={isOpen}
        onClose={onClose}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
    </>
  );
}

function GetWidgetStyle(style: WidgetStyle): {
  widget: BoxProps;
  header: BoxProps;
} {
  switch (style) {
    default:
    case 'default': {
      return {
        widget: {
          backgroundColor: 'texas.bg.900',
          _light: { backgroundColor: 'white' },
        },
        header: {
          backgroundColor: 'texas.bg.800',
          borderRadius: '8',
          _light: { backgroundColor: 'gray.100' },
        },
      };
    }
    case 'borderless': {
      return {
        widget: {
          border: '1px solid',
          borderColor: 'texas.bg.800',
          _light: {
            borderColor: 'gray.100',
          },
        },
        header: {
          border: '1px solid',
          borderRadius: '6',
          borderColor: 'texas.bg.800',
          backgroundColor: 'texas.bg.800',
          _light: {
            borderColor: 'gray.100',
            backgroundColor: 'gray.100',
          },
        },
      };
    }
  }
}
