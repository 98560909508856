import {
  HStack,
  Text,
  Button,
  ScaleFade,
  Tag,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import Select, { MultiValueGenericProps, OptionProps } from 'react-select';
import { components, MenuListProps, StylesConfig } from 'react-select';
import { PagedItems } from '../../types';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { emptyPagedItems } from '../../utils/helpers/commonHelpers';
import { FetchOptionsType, FetchValuesType, SelectFilterProps } from './types';
import { FilterContainerElement } from './FilterContainer';
import { FilterItem } from '@texas/api/endpoints/searchApi';
import { Icons } from '../shared/Icons';
import { VerifyButton } from '../shared/verifyButton/VerifyButton';

export interface FilterProps {
  valueIds: number[];
  onChange: (ids: number[]) => void;
  onRemove: () => void;
  fetchValues: FetchValuesType;
  fetchOptions: FetchOptionsType;
  minSearchTermLength: number;
  defaultIsOpen: boolean;
  name: string;
}

const pageSize = 50;
const defaultPage = 1;

export const SelectFilterElement = (props: SelectFilterProps) => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  const [loadedOptions, setLoadedOptions] =
    useState<PagedItems<FilterItem>>(emptyPagedItems);
  const [selectedOptions, setSelectedOptions] = useState<FilterItem[]>([]);
  const valueIdsWithRef = useRef<number[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingValueIdsOptions, setIsLoadingValueIdsOptions] =
    useState(false);
  const [page, setPage] = useState(defaultPage);
  const [searchQuery, setSearchQuery] = useState('');
  const onChangeDebounced = useDebouncedCallback((input: string) => {
    setPage(defaultPage);
    setSearchQuery(input);
  }, 100);
  const populateSelectableOptions =
    searchQuery.length >= props.minSearchTermLength ||
    (props.filteredOptions.length > 0 &&
      props.filteredOptions.length < pageSize);

  function setSelectedOptionsWithRef(options: FilterItem[]) {
    valueIdsWithRef.current = options.map((v) => v.value);
    setSelectedOptions(options);
  }

  const pagingProps: InternalPagingProps = {
    onLoadMore: () => {
      setPage((p) => p + 1);
    },
    loadedOptionsCount: loadedOptions.items.length,
    totalOptionsCount: loadedOptions.totalItems,
    customOptionComponentView: props.customOptionComponentView,
    customValueLabelComponentView: props.customValueLabelComponentView,
  };

  useEffect(() => {
    async function remoteFetchOptions(
      inputValue: string,
      page: number,
      fetchOptions: FetchOptionsType,
      filteredOptions: number[],
    ) {
      setIsLoadingOptions(true);
      const response = await fetchOptions({
        searchTerm: inputValue,
        page,
        pageSize,
        filteredOptions,
      });
      if (!response.hasError) {
        if (page === defaultPage) {
          setLoadedOptions(response.data);
        } else {
          setLoadedOptions((s) => ({
            ...s,
            items: [...s.items, ...response.data.items],
          }));
        }
      } else {
        setLoadedOptions(emptyPagedItems);
      }

      setIsLoadingOptions(false);
    }

    if (populateSelectableOptions) {
      remoteFetchOptions(
        searchQuery,
        page,
        props.fetchOptions,
        props.filteredOptions,
      );
    }
  }, [
    props.fetchOptions,
    page,
    populateSelectableOptions,
    searchQuery,
    props.filteredOptions,
  ]);

  useEffect(() => {
    async function remoteFetchIds(
      fetchValues: FetchValuesType,
      valueIds: number[],
    ) {
      setIsLoadingValueIdsOptions(true);
      const response = await fetchValues(valueIds);

      if (!response.hasError) {
        setSelectedOptionsWithRef(response.data);
      } else {
        setSelectedOptionsWithRef([]);
      }

      setIsLoadingValueIdsOptions(false);
    }

    if (props.valueIds.every((v) => !valueIdsWithRef.current.includes(v))) {
      remoteFetchIds(props.fetchValues, props.valueIds);
    }
  }, [props.fetchValues, props.valueIds]);

  return (
    <FilterContainerElement
      gridColumn="span 2"
      onRemove={props.onRemove}
      static={props.static}
      utilityBar={
        <VerifyButton
          buttonProps={{
            fontSize: '12px',
            size: 'sm',
            variant: 'link',
            padding: '0px 4px',
          }}
          onVerified={() => {
            setSelectedOptionsWithRef([]);
            props.onChange([]);
          }}
          label={t('searchArticles.clearAllValues')}
        />
      }
    >
      <Box>
        <Box p={1}>
          <Select
            menuPortalTarget={document.body}
            defaultMenuIsOpen={props.defaultIsOpen}
            pagingProps={pagingProps}
            components={{ MenuList, MultiValueLabel, Option }}
            styles={styles(colorMode === 'dark')}
            onChange={(value) => {
              props.onChange(value.map((v) => v.value));
              setSelectedOptionsWithRef(value.map((v) => v));
            }}
            onInputChange={(e) => onChangeDebounced(e)}
            value={selectedOptions}
            options={loadedOptions.items}
            isMulti={true}
            isClearable={false}
            autoFocus={props.defaultIsOpen}
            hideSelectedOptions={false}
            isLoading={isLoadingOptions || isLoadingValueIdsOptions}
            onMenuClose={() => {
              if (populateSelectableOptions) {
                return;
              }

              setLoadedOptions(emptyPagedItems);
            }}
            placeholder={`${t(
              'general.search',
            )} ${props.name.toLowerCase()}...`}
            name="filters"
            closeMenuOnSelect={false}
          />
        </Box>
      </Box>
    </FilterContainerElement>
  );
};

const MultiValueLabel = (props: MultiValueGenericProps<FilterItem, true>) => {
  const pagingProps = props.selectProps.pagingProps as InternalPagingProps;
  return (
    <>
      {pagingProps.customValueLabelComponentView ? (
        pagingProps.customValueLabelComponentView(props.data)
      ) : (
        <components.MultiValueLabel {...props} />
      )}
    </>
  );
};

const MenuList = (props: MenuListProps<FilterItem, true>) => {
  const { t } = useTranslation();
  const pagingProps = props.selectProps.pagingProps as InternalPagingProps;

  return (
    <components.MenuList {...props}>
      {props.children}
      {pagingProps.loadedOptionsCount < pagingProps.totalOptionsCount && (
        <Button
          disabled={props.selectProps.isLoading}
          width="100%"
          borderTop="1px solid"
          borderRadius="0"
          borderTopColor="gray.100"
          color="black"
          onClick={() => pagingProps.onLoadMore()}
        >
          {`${t('general.loadMore')} (${pagingProps.loadedOptionsCount}/${
            pagingProps.totalOptionsCount
          })`}
        </Button>
      )}
    </components.MenuList>
  );
};

const Option = (props: OptionProps<FilterItem, true>) => {
  const pagingProps = props.selectProps.pagingProps as InternalPagingProps;
  return (
    <components.Option {...props}>
      <HStack opacity={props.isSelected ? '0.6' : '1'}>
        {pagingProps.customOptionComponentView ? (
          pagingProps.customOptionComponentView(props.data)
        ) : (
          <Text color="black" fontSize={14}>
            {props.label}
          </Text>
        )}

        {props.data.tags && (
          <>
            {props.data.tags.map((l) => (
              <Tag size="sm" key={l}>
                {l}
              </Tag>
            ))}
          </>
        )}
        <Box ml="auto !important">
          <ScaleFade in={props.isSelected}>
            <Icons.Checkmark color="gray.300" boxSize={6} />
          </ScaleFade>
        </Box>
      </HStack>
    </components.Option>
  );
};

interface InternalPagingProps {
  onLoadMore: () => void;
  loadedOptionsCount: number;
  totalOptionsCount: number;
  customOptionComponentView?: (value: FilterItem) => JSX.Element;
  customValueLabelComponentView?: (value: FilterItem) => JSX.Element;
}

function styles(isDark: boolean): StylesConfig<FilterItem> {
  return {
    container: (baseStyles, _) => ({
      ...baseStyles,
      width: '100%',
    }),
    control: (baseStyles, _) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '0',
      boxShadow: 'none',
    }),
    multiValue: (baseStyles, _) => ({
      ...baseStyles,
      backgroundColor: isDark
        ? 'RGBA(255, 255, 255, 0.16)' // white alpha 300
        : 'RGBA(0, 0, 0, 0.16)', //black alpha 300
    }),
    indicatorSeparator: (baseStyles, _) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
    }),
    indicatorsContainer: (baseStyles, _) => ({
      ...baseStyles,
      alignItems: 'flex-start',
    }),
    multiValueLabel: (baseStyles, _) => ({
      ...baseStyles,
      color: isDark ? 'white' : 'black',
    }),
    option: (baseStyles, { isFocused }) => ({
      ...baseStyles,
      backgroundColor: isFocused
        ? 'RGBA(0, 0, 0, 0.08)' // black alpha 200
        : 'transparent !important',
      cursor: 'pointer',
      paddingTop: 2,
      paddingBottom: 2,
    }),
    valueContainer: (baseStyles, _) => ({
      ...baseStyles,
      padding: '0',
    }),
    input: (baseStyles, _) => ({
      ...baseStyles,
      color: isDark ? 'white' : 'black',
    }),
  };
}
