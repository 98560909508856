import {
  HStack,
  VStack,
  Box,
  IconButton,
  Tooltip,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { WidgetWrapper } from '../WidgetWrapper';
import { useTranslation } from 'react-i18next';
import {
  defaultEmptyFilters,
  useSearchArticles,
} from '../../searchArticles/useSearchArticles';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { reactEvents } from '../../../../bridge/reactEvents';
import { maxExportArticlesAmount } from '../../searchArticles/SearchArticles';

import {
  articlesWidgetSelectStyles,
  groupBadgeStyles,
  groupStyles,
} from './styles';
import { useMemo, useState } from 'react';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { Icons } from '@texas/components/shared/Icons';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import {
  GroupedOption,
  SelectFilterOption,
  ToggleFilterOption,
  GetFilterGroupedOptions,
} from './filterGroupedOptions';
import { RowSelectionState } from '@tanstack/react-table';
import { ArticlesDataTableContainerFooter } from '@texas/components/shared/dataTable/ArticlesDataTableContainerFooter';
import { useExportableArticles } from '@texas/components/searchArticles/useExportableArticles';
import { BaseWidgetProps } from '../widgets';
import { WidgetType } from '../widgetEvents';
import { SearchArticleOptions } from './types';
import { ArticlesCardView } from '@texas/components/shared/views/ArticlesCardView';

interface Props extends BaseWidgetProps {
  options: SearchArticleOptions;
  widgetId: string;
}

export function ArticlesWidget({
  options,
  customTitle,
  widgetId,
  onRemove,
  onUpdate,
  color,
}: Props) {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return options.columns(t);
  }, [options, t]);

  const {
    articles,
    searchPage,
    searchParamsRef,
    filterElements,
    error,
    loading,
    setSearchPage,
    setSearchParamsWithRef,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    availableFilters,
    handleViewChange,
    view,
  } = useSearchArticles({
    defaultFilters: defaultEmptyFilters,
    defaultArticleSearchProps: options.defaultSearchPage,
    defaultPage: options.defaultSearchPage.searchParams.page,
    defaultSearchParams: options.defaultSearchPage.searchParams,
    localStorageKey: `${options.localStorageKey}_${widgetId}`,
    filterOptions: options.filterOptions,
    optOutDefaultOrder: options.optOutDefaultOrdering,
    limit: options.pageSize,
  });

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const exportableArticles = useExportableArticles(
    selectedRows,
    articles?.exportArticleItems ?? [],
  );

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <>
      <WidgetWrapper
        settings={options.componentSettings}
        onRemove={onRemove}
        onUpdate={onUpdate}
        customColor={color}
        type={WidgetType.Articles}
        style="default"
        header={customTitle ?? t('widgets.articles.articles')}
        toolbox={WidgetToolbox()}
      >
        <VStack h="100%" w="100%" align="start">
          <HStack w="100%" align="start">
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(6, 1fr)"
              gridGap={1}
              rowGap={1}
            >
              <Box height="fit-content" mt="auto" gridColumn="span 2">
                <SearchFilterInput
                  placeholder={t('searchArticles.itemcodeOrDescription')}
                  value={searchPage.searchParams.searchTerm}
                  onChange={(searchTerm) =>
                    setSearchParamsWithRef({
                      ...searchParamsRef.current,
                      searchTerm,
                      page: options.defaultSearchPage.searchParams.page,
                    })
                  }
                />
              </Box>
              {filterElements}
            </Box>
          </HStack>
          <DataTableContainer
            hidden={view !== 'table'}
            p={4}
            w="100%"
            minH={0}
            display="flex"
            flexDir="column"
            error={error}
            css={{ ' th, td': { whiteSpace: 'normal' } }}
            datatable={{
              data: articles?.pagedItems.items ?? [],
              columns: columns,
              isLoading: loading,
              selectionProps: {
                setSelectedRows: setSelectedRows,
                selectedRows: selectedRows,
              },
              rowProps: {
                getRowId: (originalRow, _) => {
                  return originalRow.articleId.toString();
                },
              },
              sorted: {
                onSortedChange: ({ key, desc }) => {
                  setSearchParamsWithRef({
                    ...searchParamsRef.current,
                    sortBy: key,
                    sortDesc: desc,
                    page: options.defaultSearchPage.searchParams.page,
                  });
                },
                key: searchPage.searchParams['sortBy'],
                desc: searchPage.searchParams['sortDesc'],
              },
            }}
            pagination={{
              totalItems: articles?.pagedItems.totalItems ?? 0,
              pageSize: options.pageSize,
              currentPage: searchPage.searchParams.page,
              onPageChange: (page) =>
                setSearchParamsWithRef({
                  ...searchParamsRef.current,
                  page,
                }),
            }}
            footer={
              <ArticlesDataTableContainerFooter
                selectedRows={Object.keys(selectedRows).length}
                onClear={() => setSelectedRows({})}
              />
            }
          />
          <Flex
            hidden={view !== 'card'}
            w="100%"
            direction="column"
            overflowX="auto"
            overflowY="hidden"
            pb={2}
          >
            <ArticlesCardView
              articles={articles}
              searchPage={searchPage}
              searchParamsRef={searchParamsRef}
              setSearchParamsWithRef={setSearchParamsWithRef}
              error={error}
              loading={loading}
            />
          </Flex>
        </VStack>
      </WidgetWrapper>
    </>
  );

  function WidgetToolbox() {
    return (
      <VStack>
        <Tooltip label={t('searchArticles.addNewFilter')}>
          <Box>
            <Select<
              SelectFilterOption | ToggleFilterOption,
              false,
              GroupedOption
            >
              menuPortalTarget={document.body}
              inputId="clickableInput"
              placeholder=""
              styles={articlesWidgetSelectStyles}
              components={{ DropdownIndicator }}
              options={GetFilterGroupedOptions(availableFilters)}
              formatGroupLabel={formatGroupLabel}
              onChange={(value) => {
                if (!value) return;
                const type = value.value;
                setActiveFiltersVariantResults((s) => [
                  ...s,
                  {
                    optionType: type,
                    ...defaultEmptyFilters,
                    isPopulated: false,
                  },
                ]);
                setActiveFilterTypes((s) => [...s, [true, type]]);
                setSearchPage((s) => ({
                  ...s,
                  filters: [...s.filters, type],
                }));
              }}
              value={null}
            />
          </Box>
        </Tooltip>

        <Tooltip label={t('general.exportExcel')}>
          <IconButton
            variant="unstyled"
            aria-label="Export excel"
            size="xs"
            isDisabled={
              Object.keys(selectedRows).length === 0 ||
              Object.keys(selectedRows).length > maxExportArticlesAmount
            }
            onClick={() => {
              reactEvents.excelExportClicked.dispatch({
                articles: exportableArticles,
                branchIds: searchPage.searchParams['branchIds'] ?? [],
                hideBranchIds: false,
              });
            }}
            icon={<Icons.FileExport boxSize={6} />}
          />
        </Tooltip>
        <Divider my={4} />
        <Tooltip label={t('view.tableView')}>
          <IconButton
            variant={view === 'table' ? 'texas-solid' : 'texas-light'}
            size="sm"
            icon={<Icons.viewTable />}
            aria-label={t('view.tableView')}
            onClick={() => handleViewChange('table')}
          />
        </Tooltip>
        <Tooltip label={t('view.cardView')}>
          <IconButton
            variant={view === 'card' ? 'texas-solid' : 'texas-light'}
            size="sm"
            icon={<Icons.viewCard />}
            aria-label={t('view.cardView')}
            onClick={() => handleViewChange('card')}
          />
        </Tooltip>
      </VStack>
    );
  }
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<
    SelectFilterOption | ToggleFilterOption,
    false,
    GroupedOption
  >,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.Plus
        boxSize="6"
        cursor="pointer"
        color="white"
        _hover={{ color: 'gray.50' }}
      />
    </components.DropdownIndicator>
  );
};
