import { useActiveSession } from '@texas/hooks/useSession';
import { useWidgetLayout } from '../widgets/useWidgetLayout';
import { WidgetType } from '../widgets/widgetEvents';
import { TableColumns } from './TableColumns';
import { defaultCustomerWidgetsLayout } from './defaultCustomerWidgetsLayout';
import {
  defaultArticlesWidgetOptions,
  pageSize,
} from './defaultWidgetsOptions';
import { useTranslation } from 'react-i18next';
import { BodyOverride } from '../shared/BodyOverride';

const localStorageCustomerSuffix = 'c';
const localStorageConceptSuffix = 'concept';

export function Customer({
  customerId,
  conceptId,
}: {
  customerId: number | undefined;
  conceptId: number | undefined;
}) {
  const { t } = useTranslation();
  const session = useActiveSession();
  const { widgetComponents } = useWidgetLayout({
    defaultLayout: defaultCustomerWidgetsLayout,
    widgetOptions: {
      [WidgetType.Articles]: {
        ...defaultArticlesWidgetOptions(customerId, conceptId),
        localStorageKey: localStorageKey(
          session.currentUser.id,
          customerId,
          conceptId,
        ),
        columns: TableColumns,
        customWidgetTitle: t('general.brandArticles'),
        pageSize: pageSize,
        optOutDefaultOrdering: true,
        componentSettings: {
          settingDots: 'hidden',
        },
      },
    },
  });

  return <BodyOverride>{widgetComponents}</BodyOverride>;
}

function localStorageKey(
  userId: number,
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  return customerId
    ? `${userId}_${customerId}_${localStorageCustomerSuffix}`
    : conceptId
    ? `${userId}_${conceptId}_${localStorageConceptSuffix}`
    : undefined;
}
