import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const lineVariant = config.definePartsStyle({
  tablist: {
    color: 'whiteAlpha.600',
    borderBottomColor: 'whiteAlpha.400',
    _light: {
      color: 'gray.300',
      borderBottomColor: 'blackAlpha.200',
    },
  },
  tab: {
    _selected: {
      borderBottomColor: 'texas.sand.100 !important',
      color: 'white',
    },
    _light: {
      color: 'gray.300',
      _selected: {
        color: 'texas.bg.900',
        borderBottomColor: 'texas.burntSienna.500 !important',
      },
    },
  },
});

const stepVariant = config.definePartsStyle({
  tablist: {
    bg: 'gray.800',
    _light: { bg: 'gray.50' },
  },
  tab: {
    width: 'auto',
    height: '2rem',
    ml: '-16px',
    position: 'relative',
    bg: 'gray.800',
    _selected: {
      bg: 'gray.600',
      color: 'white',
      _light: {
        color: 'texas.bg.900',
      },
    },
  },
});

const variants = { line: lineVariant, step: stepVariant };

export default config.defineMultiStyleConfig({
  defaultProps: { variant: 'line' },
  variants,
});
