import { useCallback, useMemo } from 'react';
import { BrandsWidget } from './Brands/BrandsWidget';
import { ArticlesWidget } from './Articles/ArticlesWidget';
import { Box } from '@chakra-ui/react';
import { WidgetsLayout } from './WidgetsContainer';
import { WidgetType, widgetEvents } from './widgetEvents';
import { defaultWidgetLayouts } from './defaultWidgetLayouts';
import { useLocalStorage } from '@texas/hooks/useLocalStorage';
import { randomSeed } from '@texas/resources/constants';
import { SearchArticleOptions } from './Articles/types';

export type WidgetOptions = SearchArticleOptions;
type Option = {
  [_ in WidgetType]?: WidgetOptions;
};

export interface Widget {
  id: string;
  type: WidgetType;
  layoutIndex: string;
  name: string;
  categoryColor: string | null;
}

export interface WidgetLayout {
  layout: ReactGridLayout.Layouts;
  widgets: Widget[];
  currentLayout?: ReactGridLayout.Layout[];
}

export interface WidgetLayoutProps {
  defaultLayout: WidgetLayout;
  localStorageKey?: string;
  widgetOptions: Option;
}

export const useWidgetLayout = (props: WidgetLayoutProps) => {
  const [layout, setLayout] = useLocalStorage(
    props.localStorageKey,
    props.defaultLayout,
  );

  const addNewWidget = useCallback(
    (widget: WidgetType, name: string, color: string | null) => {
      const widgetId = Math.floor(Math.random() * randomSeed).toString();

      setLayout((l) => ({
        layout: {
          ['lg']: [
            ...l.layout['lg'],
            { ...defaultWidgetLayouts[widget].lg, i: widgetId },
          ],
          ['md']: [
            ...l.layout['md'],
            { ...defaultWidgetLayouts[widget].lg, i: widgetId },
          ],
        },
        widgets: [
          ...l.widgets,
          {
            id: widgetId,
            type: widget,
            layoutIndex: widgetId,
            name: name,
            categoryColor: color,
          },
        ],
        currentLayout: l.currentLayout,
      }));
    },
    [setLayout],
  );

  const widgets = useMemo(() => {
    function removeWidget(id: string) {
      setLayout((l) => ({
        ...l,
        widgets: [...l.widgets.filter((x) => x.id !== id)],
      }));
    }

    function updateWidget(id: string, name: string, color: string | null) {
      setLayout((l) => ({
        ...l,
        widgets: [
          ...l.widgets.map((x) => {
            if (x.id === id) {
              return { ...x, name, categoryColor: color };
            }

            return x;
          }),
        ],
      }));
    }

    return (
      <WidgetsLayout
        onResize={(_layout, _oldItem, newItem) => {
          const widget = layout.widgets.find(
            (w) => w.layoutIndex === newItem.i,
          );
          if (!widget) return;
          triggerEvent(widget.type, newItem.w, newItem.h);
        }}
        onLayoutChange={onLayoutChange}
        layout={layout.layout}
      >
        {layout.widgets.map((w) => {
          const widgetLayout = layout.currentLayout?.find(
            (x) => x.i === w.layoutIndex,
          );
          return (
            <Box key={w.layoutIndex}>
              {widgetComponent(
                w.type,
                widgetLayout?.h ?? 0,
                w.id,
                updateWidget,
                removeWidget,
                w.categoryColor,
                w.name,
                props.widgetOptions[w.type],
              )}
            </Box>
          );
        })}
      </WidgetsLayout>
    );

    function onLayoutChange(
      currentLayout: ReactGridLayout.Layout[],
      allLayouts: ReactGridLayout.Layouts,
    ) {
      setLayout((s) => ({
        widgets: s.widgets,
        layout: allLayouts,
        currentLayout: currentLayout,
      }));
    }
  }, [
    layout.layout,
    layout.widgets,
    layout.currentLayout,
    props.widgetOptions,
    setLayout,
  ]);

  return { widgetComponents: widgets, addNewWidget, widgets: layout.widgets };
};

function widgetComponent(
  widgetType: WidgetType,
  height: number,
  id: string,
  onUpdate: (id: string, name: string, color: string | null) => void,
  onRemove: (id: string) => void,
  customColor: string | null,
  name?: string,
  options?: WidgetOptions,
) {
  switch (widgetType) {
    case WidgetType.Brands:
      return (
        <BrandsWidget
          options={options}
          onRemove={() => onRemove(id)}
          onUpdate={(name, color) => onUpdate(id, name, color)}
          color={customColor}
          height={height}
          customTitle={options?.customWidgetTitle ?? name}
        />
      );
    case WidgetType.Articles:
      return (
        <ArticlesWidget
          onRemove={() => onRemove(id)}
          onUpdate={(name, color) => onUpdate(id, name, color)}
          color={customColor}
          widgetId={id}
          customTitle={options?.customWidgetTitle ?? name}
          options={options!}
        />
      );
  }
}

function triggerEvent(event: WidgetType, width: number, height: number) {
  if (!widgetEvents.resize[event]) return;
  widgetEvents.resize[event]?.dispatch({
    widget: event,
    width: width,
    height: height,
  });
}
