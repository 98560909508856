import { Grid, HStack, Spinner, VStack, Text, Box } from '@chakra-ui/react';
import { SearchFilterInput } from '../../SearchFilterInput';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import { BrandCard } from './BrandCard';
import { WidgetType, widgetEvents } from '../widgetEvents';
import { angularEvents } from '../../../../bridge/angularEvents';
import { useTranslation } from 'react-i18next';
import { WidgetWrapper } from '../WidgetWrapper';
import { SwitchFilterElement } from '../../filter/SwitchFilter';
import { customersApi, BrandsDto } from '@texas/api/endpoints/customersApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useActiveSession } from '@texas/hooks/useSession';
import { ClaimRestrict } from '@texas/components/shared/ClaimRestrict';
import { ClaimType } from '@texas/api/endpoints/userApi';
import { BaseWidgetProps } from '../widgets';
import { BaseWidgetOptions } from '../types';

const maxBrandsPerRow = 20;

interface Props extends BaseWidgetProps {
  height: number;
  options?: BaseWidgetOptions;
}

export function BrandsWidget({
  height,
  onRemove,
  onUpdate,
  color,
  customTitle,
  options,
}: Props) {
  const {
    data: brands,
    refetch,
    loading,
    set,
  } = useApiResource(customersApi.filterCustomers);
  const [filter, setFilter] = useState('');
  const { currentUser } = useActiveSession();
  const [userId, setUserId] = useState<number | null>(currentUser.id);

  useEffect(() => {
    if (!userId && filter.length === 0) {
      set(null);
      return;
    }
    refetch({
      assignedTo: userId,
      customerName: filter,
    });
  }, [userId, filter, refetch, set]);

  const { t } = useTranslation();
  useEffect(() => {
    return angularEvents.newBrandCreated.subscribe((brand: BrandsDto) => {
      set((s) => (s === null ? [brand] : [...s, brand]));
    });
  });

  return (
    <WidgetWrapper
      settings={options?.componentSettings}
      customColor={color}
      type={WidgetType.Brands}
      onRemove={onRemove}
      onUpdate={onUpdate}
      style="borderless"
      header={customTitle ?? t('widgets.brands.yourBrands')}
    >
      <VStack align="start" pos="relative" height="100%">
        <HStack>
          <ClaimRestrict
            allow={[
              ClaimType.CompanyAdministrator,
              ClaimType.OrganizationAdministrator,
              ClaimType.SystemAdministrator,
            ]}
          >
            <SwitchFilterElement
              static={false}
              onChange={(value) => {
                setUserId(value ? null : currentUser.id);
              }}
              offLabel={t('filter.myBrands')}
              onLabel={t('filter.allBrands')}
              checked={userId === null}
              name={t('filter.myBrands')}
            />
            <SearchFilterInput
              style="unstyled"
              value={filter}
              placeholder={t('widgets.brands.filterBrands')}
              onChange={(value) => setFilter(value)}
            />
          </ClaimRestrict>

          {loading && (
            <Box>
              <Spinner />
            </Box>
          )}
        </HStack>
        <Brands brands={brands} isLoading={loading} height={height} />
      </VStack>
    </WidgetWrapper>
  );
}

function Brands({
  brands,
  isLoading,
  height,
}: {
  brands: BrandsDto[] | null;
  isLoading: boolean;
  height: number;
}) {
  const { t } = useTranslation();
  if (!brands && !isLoading) {
    return (
      <Text margin="auto !important" alignSelf="center">
        {t('widgets.brands.filterAbove')}
      </Text>
    );
  }
  return <BrandsInnerComponent brands={brands} height={height} />;
}

function BrandsInnerComponent({
  brands,
  height,
}: {
  brands: BrandsDto[] | null;
  height: number;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
  const [rows, setRows] = useState(height - 1);
  const currentRows = useRef(rows);

  useEffect(() => {
    return widgetEvents.resize[WidgetType.Brands].subscribe((event) => {
      if (currentRows.current != event.height) {
        setRows(event.height - 1);
        currentRows.current = event.height;
      }
    });
  });
  return (
    <Grid
      maxW="100%"
      overflow="auto"
      gridAutoFlow="column"
      gridTemplateRows={`repeat(${getRowsCount(
        brands?.length ?? 0,
        rows,
      )}, max-content)`}
      gridTemplateColumns={`repeat(${getColumnsCount(
        brands?.length ?? 0,
      )}, max-content)`}
      gap={2}
      ref={ref}
      {...events}
    >
      {brands?.map((b) => (
        <BrandCard
          key={b.id}
          id={b.id}
          name={b.name}
          nrOfArticles={b.nrOfArticles}
        />
      ))}
    </Grid>
  );
}

function getColumnsCount(nrOfBrands: number) {
  return nrOfBrands > maxBrandsPerRow ? maxBrandsPerRow : nrOfBrands;
}

function getRowsCount(nrOfBrands: number, rows: number) {
  const minRows = Math.ceil(nrOfBrands / maxBrandsPerRow);
  return rows < minRows ? minRows : rows;
}
