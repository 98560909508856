import {
  Text,
  VStack,
  GridItem,
  Badge,
  Link,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { fadeInScaleAnimation } from '../../../resources/animations/animations';
import { useTranslation } from 'react-i18next';

export interface BrandCardProps {
  name: string;
  id: number;
  nrOfArticles: number;
}

export function BrandCard(props: BrandCardProps) {
  const { t } = useTranslation();
  return (
    <GridItem>
      <Card variant="brand" animation={fadeInScaleAnimation()}>
        <CardBody>
          <Link
            textDecoration="none"
            href={`#/customers/${props.id}`}
            pos="relative"
          >
            <VStack
              flexGrow={1}
              minW={100}
              justify="space-between"
              align="start"
            >
              <Text fontSize={18}>{props.name}</Text>
              <Badge>
                {props.nrOfArticles} {t('general.articles')}
              </Badge>
            </VStack>
          </Link>
        </CardBody>
      </Card>
    </GridItem>
  );
}
