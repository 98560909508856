import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react';
import { CompositionGroupOverview } from '@texas/api/endpoints/compositionGroupApi';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { VersionsContext } from './VersionsGridOverview';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { clientEndpoints } from '@texas/clientEndpoints';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { ConfirmCopy } from '../shared/group/ConfirmCopy';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { RefObject } from 'react';
import { menuButtonProps } from '../shared/props';
import { UpdateGroupModal } from '../shared/group/UpdateGroupModal';

export function VersionItem({
  group,
  basedOnOption,
  options,
  onClick,
  gridRef,
}: {
  group: CompositionGroupOverview;
  basedOnOption?: CompositionGroupOverview;
  options: CompositionGroupOverview[];
  onClick: () => void;
  gridRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const {
    setBasedOnRequest,
    copyCompositionGroupRequest,
    anyProductApproval,
    refetchVersions,
  } = useActiveContext(VersionsContext);
  const { colorMode } = useColorMode();

  const { isOpen, onClose, onOpen, value } = useValueDisclosure<{
    version: number;
    id: number;
    title: string;
  }>();

  const {
    isOpen: isEditGroupOpen,
    onClose: onEditGroupClose,
    onOpen: onEditGroupOpen,
    value: editGroupValue,
  } = useValueDisclosure<{ id: number; note: string | null }>();

  const anyProductApprovalOnGroup = anyProductApproval(group.id);
  return (
    <>
      <Button
        animation={fadeInScaleAnimation()}
        as={Flex}
        justifyContent="space-between"
        alignItems="center"
        variant="comp-version"
        onClick={onClick}
        border="1px solid"
        borderColor="transparent"
        _hover={{
          borderColor: 'white',
          transform: 'scale(1.02)',
          _light: { borderColor: 'black' },
        }}
        {...menuButtonProps(
          gridRef,
          group.supplierId ?? 0,
          colorMode === 'dark',
        )}
      >
        <Flex flexDir="column">
          <Text>{t('composition.versionNr', { nr: group.version })}</Text>
          <Text
            variant="sub"
            fontWeight="normal"
            fontSize="sm"
            whiteSpace="normal"
          >
            {group.note}
          </Text>
          {!basedOnOption && (
            <Text fontSize="sm" color="gray.400">
              {t('composition.noOption')}
            </Text>
          )}
        </Flex>
        <Flex gap={3} alignItems="center">
          <ProductApprovals group={group} />
          <Menu placement="top">
            <MenuButton
              as={IconButton}
              size="sm"
              variant="ghost"
              onClick={(e) => e.stopPropagation()}
              icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
            />
            <Portal>
              <MenuList color="white">
                <MenuItem
                  icon={<Icons.NoteEdit boxSize={defaultIconSize} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditGroupOpen({ id: group.id, note: group.note });
                  }}
                >
                  {t('composition.editNote')}
                </MenuItem>
                {!basedOnOption && options.length > 0 && (
                  <>
                    {options.map((o) => {
                      return (
                        <MenuItem
                          icon={<Icons.ArrowUpLeft boxSize={defaultIconSize} />}
                          key={o.id}
                          onClick={(e) => {
                            setBasedOnRequest(group.id, o.id);
                            e.stopPropagation();
                          }}
                        >
                          {t('composition.moveToOptionNr', { nr: o.version })}
                        </MenuItem>
                      );
                    })}
                  </>
                )}
                {basedOnOption && !anyProductApprovalOnGroup && (
                  <>
                    <MenuItem
                      icon={<Icons.ArrowRight boxSize={defaultIconSize} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpen({
                          version: basedOnOption.version,
                          id: basedOnOption.id,
                          title: `Replace with option ${basedOnOption.version}`,
                        });
                      }}
                    >
                      {t('composition.replaceWithOptionNr', {
                        nr: basedOnOption.version,
                      })}
                    </MenuItem>
                  </>
                )}
                {anyProductApprovalOnGroup && (
                  <MenuItem
                    icon={<Icons.ContentCopy boxSize={defaultIconSize} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpen({
                        version: group.version,
                        id: group.id,
                        title: t('composition.copyNrIntoNewVersion', {
                          nr: group.version,
                        }),
                      });
                    }}
                  >
                    {t('composition.copyIntoNewVersion')}
                  </MenuItem>
                )}
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      </Button>
      {value && (
        <ConfirmCopy
          title={value.title}
          isOpen={isOpen}
          onClose={onClose}
          compositionGroupId={value.id}
          onConfirm={(id) => {
            onClose();
            if (anyProductApproval(group.id)) {
              copyCompositionGroupRequest(id, value.id, undefined);
              return;
            }
            copyCompositionGroupRequest(id, value.id, group.id);
          }}
        />
      )}
      <UpdateGroupModal
        onUpdated={refetchVersions}
        group={editGroupValue}
        onClose={onEditGroupClose}
        isOpen={isEditGroupOpen}
      />
    </>
  );
}

function ProductApprovals({ group }: { group: CompositionGroupOverview }) {
  const { productApprovalsCount } = useActiveContext(VersionsContext);
  const { t } = useTranslation();
  return (
    <>
      {productApprovalsCount(ProductApprovalState.Pending, group.id) > 0 && (
        <Tooltip
          label={t('composition.pendingPa', {
            count: productApprovalsCount(
              ProductApprovalState.Pending,
              group.id,
            ),
          })}
        >
          <Box>
            <Link
              href={clientEndpoints.articleProductApproval(group.articleId, {
                branchIds: group.branchId ? [group.branchId] : undefined,
                state: ProductApprovalState[
                  ProductApprovalState.Pending
                ] as keyof typeof ProductApprovalState,
                supplierIds: group.supplierId ? [group.supplierId] : undefined,
              })}
            >
              <Flex alignItems="center" gap={1}>
                <Icons.Clock boxSize={defaultIconSize} />
                <Text fontFamily="Instrument sans" fontWeight="bold">
                  {productApprovalsCount(
                    ProductApprovalState.Pending,
                    group.id,
                  )}
                </Text>
              </Flex>
            </Link>
          </Box>
        </Tooltip>
      )}
      {productApprovalsCount(ProductApprovalState.Accepted, group.id) > 0 && (
        <Tooltip
          label={t('composition.acceptedPa', {
            count: productApprovalsCount(
              ProductApprovalState.Accepted,
              group.id,
            ),
          })}
        >
          <Box>
            <Link
              color="texas.sand.100"
              href={clientEndpoints.articleProductApproval(group.articleId, {
                branchIds: group.branchId ? [group.branchId] : undefined,
                state: ProductApprovalState[
                  ProductApprovalState.Accepted
                ] as keyof typeof ProductApprovalState,
                supplierIds: group.supplierId ? [group.supplierId] : undefined,
              })}
            >
              <Flex
                _light={{ color: 'texas.burntSienna.500' }}
                alignItems="center"
                gap={1}
              >
                <Icons.CheckCircle boxSize={defaultIconSize} />
                <Text fontFamily="Instrument sans" fontWeight="bold">
                  {productApprovalsCount(
                    ProductApprovalState.Accepted,
                    group.id,
                  )}
                </Text>
              </Flex>
            </Link>
          </Box>
        </Tooltip>
      )}
    </>
  );
}
