import { SignalDispatcher } from 'ste-signals';
import { SimpleEventDispatcher } from 'ste-simple-events';

export const compositionGroupEvents = {
  compositionGroupsChanged: new SignalDispatcher(),
  optionArchivedOrRestored: new SignalDispatcher(),
  versionArchivedOrRestored: new SimpleEventDispatcher<number>(),
  compositionMaterialUpdated: new SignalDispatcher(),
  compositionCellOptionCopied: new SimpleEventDispatcher<{
    cellX: number;
    cellY: number;
  }>(),
};
